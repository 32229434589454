<template>
  <v-container fluid style="background-color:#fff">
    <v-layout wrap align-start justify-start row fill-height>
      <v-col cols="12" xs="12" md="10" offset-md="1">
        <v-card>
          <v-card-title>
              {{ $t('lang.route_categories') }}
          </v-card-title>
          <v-divider />
          <data-table
            :items="items"
			      @dataUpdated="getAllRoutesList"
          />
        </v-card>
      </v-col>
    </v-layout>
  </v-container>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'
import axios from 'axios'
import { Watch } from 'vue-property-decorator'
import moment from 'moment'
import VueCookie from 'vue-cookie'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { notifications } from '@/utils/messages'
import { getAllRoutes } from '@/api/home'
import List from '@/components/home/List'

@Component({
  components: {
    'data-table': List
  },
  computed: {
    ...mapGetters('app', ['isFixedHeader']),
  },
  methods: {
    ...mapActions('authentication', [
      'logout'
    ]),
    ...mapMutations('app', [
      'toggleLoader'
    ])
  }
})
class CoreCalendar extends Vue {
  data() {
    return {
      search: '',
      mustSort: true,
      totalItems: 0,
      toPath: '/parts/actions',
      pagination: {
        rowsPerPage: 10
      },
      filters: {
        search_value: '',
      },
      items: []
    }
  }

  mounted() {
    this.getAllRoutesList() 
  }

  getAllRoutesList () {
    this.toggleLoader()
    const params = {
      search_value: '',
      page: 1,
      per_page: 999999,
    }

    getAllRoutes(params).then(data => {
      this.items = data.data.data
      this.totalItems = data.data.total
      this.pagination.totalItems = data.data.total
    })
    .then(() => this.toggleLoader())
    .catch((error) => this.handleServerSideValidationError(error))
  }
}

export default CoreCalendar

</script>