<template>
  <v-layout wrap align-start justify-start row>
    <v-col cols="12" xs="12">
      <v-list class="overflow-y-auto" v-if="items.length > 0" subheader>
        <v-list-group prepend-icon="mdi-equal-box" v-for="(category) in items"  v-model="category.selectedMenu" :key="category.id" no-action>
          <template v-slot:activator>
            <v-list-item-title v-text="category.name"></v-list-item-title>
          </template>
          <template v-if="category.routes_relation !== 0">
            <v-list-item-content>
              <v-simple-table dense>
                <template v-slot:default>
                  <tbody>
                    <tr v-for="(item) in category.routes_relation" :key="item.id">
                      <td width="2%">
                        <v-icon>mdi-source-pull</v-icon>
                      </td>
                      <td width="78%">
                        <span v-if="checkRouteLog(item.route_log)">
                          {{ item.name }}
                        </span>
                        <span v-else>
                          <a class="text-xs-right inline-navigator"  @click="$router.push(`/home/${item.id}`)">
                            {{ item.name }}
                          </a>
                        </span>
                      </td>
                      <td width="10%">
                        <v-avatar outlined>
                          <v-icon v-if="item.active != 0 && item.active != null" color="#247727">check_circle</v-icon>
                          <v-icon v-else class="grey--text">error_outline</v-icon>
                        </v-avatar>
                      </td>
                      <td width="10%">
                        <v-btn icon color="blue" v-if="checkRouteLog(item.route_log)" @click='openDialoag(item)'>
                          <v-icon>mdi-play</v-icon>
                        </v-btn>
                        <v-btn icon color="red" v-else @click='closeRoute(item.route_log.id)'>
                          <v-icon>mdi-pause</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-list-item-content>
          </template>
        </v-list-group>
      </v-list>
    </v-col>
    <v-dialog v-model='dialog' persistent max-width='400px'>
      <v-card>
        <v-form ref="vehicleForm" lazy-validation>
          <v-card-title>
            <span class='headline'>
              {{ $t('lang.choose_vehicle') }}
            </span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-select
                  v-model='vehicle'
                  :items='vehicles' 
                  :label="$t('lang.vehicle')"
                  item-text="name"
                  item-value="id"
                  clearable
                  outlined
                  :searchable="false"
                  dense
                  :disable-lookup="true"
                  :rules="requiredRules"
                />
              <v-checkbox :label="$t('lang.useCurrentPosition')" color="info" v-model="currentPosition" />
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color='primary'
              @click="playRoute()"
            >
              <v-icon class="mr-1 pa-0">
                mdi-content-save
              </v-icon>
              {{  $t('lang.start') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color='error'
              @click="dialog = false"
            >
              <v-icon class="mr-1 pa-0">
                mdi-cancel
              </v-icon>
              {{ $t('lang.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <div style="display:none" id="mapid" ref="mapContainer"></div>
  </v-layout>
</template>

<script>
import Vue from 'vue'
import * as L from "leaflet"
import "proj4leaflet"
import Component from 'vue-class-component'
import { notifications, confirmation } from '@/utils/messages'
import { createRouteLog, toggleRouteLog, getVehicle, checkCloseRoute, checkRoute, saveSummary } from '../../api/routelogs'

@Component({
  props: {
    items: {
      type: Array,
      default: []
    }
  },
  computed: {
    updatePagination: {
      get() {
        return this.pagination
      },
      set(value) {
        this.$emit('paginationUpdate', value)
      }
    }
  }
})
class List extends Vue {
  data() {
    return { 
      dialog: false,
      vehicle: '',
      vehicles: [],
      listItemRef: {},
      userId: '',
      hiddenMapRef: '',
      currentLat: '',
      currentLan: '',
      currentPosition: false,
      requiredRules: [
        (v) => !!v || '',
      ]
    }
  }

  mounted() {
    const self = this
    const user = self.$cookie.get('user-details')
    self.userId = JSON.parse(user).id;
    self.hiddenMapRef = L.map('mapid').setView([61.101125967765014, 7.445614605369609], 15);
    L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '©OpenStreetMap',
      maxZoom: 18
    }).addTo(self.hiddenMapRef)
    self.hiddenMapRef.locate({setView: false, timeout: 10000000000000}) /* This will return map so you can do chaining */
      .on('locationfound', function(e){
        self.currentLat = e.latlng.lat
        self.currentLan = e.latlng.lng
      })
      .on('locationerror', function(e){
        self.handleNotificationSuccessOrFainMsg(e.message, 'error')
      });
  }


  openDialoag(item) {
    // let existingRun= [];
    const self = this
    const user = self.$cookie.get('user-details')
    checkRoute({
      userId: self.userId,
      routeId: item.id,
    }).then(data => {
      if (data.canRun === 1) {
        self.listItemRef = item
        self.loadVehicles()  
      } else if (data.canRun === 2) {
        self.handleNotificationSuccessOrFainMsg(self.$t("lang.alreadyRouteInUse"), 'error')
      } else if (data.canRun === 3) {
        self.handleNotificationSuccessOrFainMsg(self.$t("lang.routeIsUsedAnotherDriver"), 'error')
      } else if (data.canRun === 4) {
        self.handleNotificationSuccessOrFainMsg(self.$t("lang.noWayPoints"), 'error')
      }
    })
  }

  loadVehicles() {    
    const self = this
    getVehicle({
      page: 1,
      per_page: -1
    }).then(data => {
      const listItems = data.data.data.length !== 0 ? JSON.parse(JSON.stringify(data.data.data.filter(x => x.active === 1))) : []
      listItems.filter(x => x.active = 0)
      self.vehicles = listItems
      self.dialog = true
    })
  }


  playRoute() {
    const self = this
    if (self.$refs.vehicleForm.validate()) { 
      self.dialog = false
      self.startRoute(self.listItemRef, self.vehicle)
      self.vehicle = ''
    }
  }

  // should return true is routeLog exists and not null
  checkRouteLog(routeLog) {
    if (routeLog !== null && routeLog.user_id === this.userId) {
      return false;
    }
    return true;
  }

  startRoute(item, vehicle) {
    const self = this
    createRouteLog({
      total: 0,
      net: 0,
      gross: 0,
      total_km: 0,
      hours: 0,
      route_id: item.id,
      vehicle: vehicle
    }).then(response => {
      if (self.currentPosition === true) {
        self.$router.push('/home/'+item.id+'/'+self.currentLat+'/'+self.currentLan)
      } else {
        self.$router.push('/home/'+item.id)
      }
    })
  }

  closeRoute(id) {
    const self = this
    checkCloseRoute(id)
      .then(response => {
        if (response.canClose === 1 || response.canClose === 2) {
         confirmation(self, self.$t("lang.closeConfirmMsg"), self.$t("lang.closeConfirm"))
          .then((res) => {
            if (res) {
              if (response.canClose === 2) {
                toggleRouteLog({ id: id}).then(data => {
                  self.handleNotificationSuccessOrFainMsg(self.$t("lang.routeClosedSuccessfully"), 'success')
                  self.$emit('dataUpdated')
                })
              } else {
                 saveSummary({ id: id }).then(data => {
                  this.handleNotificationSuccessOrFainMsg(this.$t("lang.routeClosedSuccessfully"), 'success')
                  self.$emit('dataUpdated')
                })
              }
            }
          })
        } else {
          this.handleNotificationSuccessOrFainMsg(this.$t("lang.completeAllRoutes"), 'error')
        }
      })
  }
}
export default List
</script>